import React from 'react'; 

const FieldAPlus = ({
  value,
  style,
}) => {
  if (!value) {
    return null;
  }

  // TODO fix backend to not return sketchy style background color value
  // This is what it returns when A+ is red
    // style: {…}
      // backgroundColor: "rgba(181, 14, 26, 0.5)"
  // Otherwise it returns
    // style: {…}
      // backgroundColor: "rgba(138, 235, 138, 0.5)
  const aColorClass = style?.backgroundColor === 'rgba(181, 14, 26, 0.5)' ? 'card-red' : '';

  return (
    <span className={`color-item aplus-card ${aColorClass}`}>A+</span>
   );
}

export default FieldAPlus

