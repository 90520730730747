import React, {memo, useContext} from 'react';
import {isEqual, differenceWith} from 'lodash';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ScannerTableHeader from './ScannerTableHeader';
import ScannerRow from './ScannerRow';
import AppContext from '../../Context/AppContext';

const ScannerTable = ({
  inView,
  columns,
  handleRequestSort,
  handleChangeRowsPerPage,
  changePage,
  count,
  dataTypes,
  allowedChart,
  groupName,
  orderState,
  orderByState,
  emptyRows,
  rowsPerPageState,
  pageState,
}) => {
  // TODO since this is context probably move to ScannerRow or needed Cells
  const {activeWatchlistSymbols} = useContext(AppContext);

  const noData = inView.length <= 0;

  return (
    <Box sx={{width: 'auto', mx: 'auto'}}>
      <Paper sx={{width: 'auto', mb: 2}}>
        <TableContainer sx={{maxHeight: '80vh'}}>
          <Table stickyHeader sx={{minWidth: 0}} aria-labelledby="tableTitle" size="small">
            {dataTypes?.length > 0 && (
              <ScannerTableHeader
                order={orderState}
                orderBy={orderByState}
                onRequestSort={handleRequestSort}
                tableHeaders={dataTypes}
                multiScreen={false}
              />
            )}
            {inView && inView.length ? (
              <TableBody>
                {inView.map((stockDataEntry) => (
                  <ScannerRow
                    key={`${stockDataEntry.getEntryId()}-row`}
                    stockDataEntry={stockDataEntry}
                    columns={columns}
                  />
                ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow rowSpan={10} colSpan={10}>
                  <TableCell align="center" rowSpan={10} colSpan={10}>
                    <h1 className="no-results text-[#b2b6ce] text-3xl mt-4">
                      {noData ? 'No Data Available' : 'No Results Found'}
                    </h1>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPageState}
          page={pageState}
          onPageChange={changePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Paper>
    </Box>
  );
};
/* TODO
export default memo(ScannerTable, (prev, next) => {
  const inViewSame = isEqual(prev?.inView, next?.inView);
  const orderSame = prev?.orderState === next?.orderState;
  const orderByStateSame = prev?.orderByState === next?.orderByState;
  const dataTypesSame = isEqual(prev?.dataTypes, next?.dataTypes);
  const allowedChartSame = prev?.allowedChart === next?.allowedChart;
  const allowedWatchlistSame = prev?.allowedWatchlist === next?.allowedWatchlist;
  const groupNameSame = prev?.groupName === next?.groupName;
  const noDataSame = prev?.noData === next?.noData;
  const emptyRowsSame = prev?.emptyRows === next?.emptyRows;
  // const isSame =
  //   inViewSame &&
  //   orderSame &&
  //   orderByStateSame &&
  //   dataTypesSame &&
  //   allowedChartSame &&
  //   allowedWatchlistSame &&
  //   groupNameSame &&
  //   noDataSame &&
  //   emptyRowsSame;
  // if (!isSame) {
  //   console.log('ScannerTable changed');
  //   console.log(
  //     inViewSame,
  //     orderSame,
  //     orderByStateSame,
  //     dataTypesSame,
  //     allowedChartSame,
  //     allowedWatchlistSame,
  //     groupNameSame,
  //     noDataSame,
  //     emptyRowsSame,
  //   );
  // }
  return (
    inViewSame &&
    orderSame &&
    orderByStateSame &&
    dataTypesSame &&
    allowedChartSame &&
    allowedWatchlistSame &&
    groupNameSame &&
    noDataSame &&
    emptyRowsSame
  );
});
*/
export default ScannerTable;
