/* eslint-disable camelcase */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React, {useEffect, useRef, useState, useContext, useSyncExternalStore} from 'react';
import TableRow from '@mui/material/TableRow';

import MemoizedScannerCell from './ScannerCell';
import StockDataStore from '../../externalStoreStocksData';

const ScannerRow = ({stockDataEntry, columns}) => {
  const cellRef = useRef(null);

  const stockSymbol = stockDataEntry.getSymbol();
  const stockGroup = stockDataEntry.getGroup();
  const rowId = stockDataEntry.getEntryId();

  const stockRowData = useSyncExternalStore(
    (callback) => StockDataStore.getInstance(stockGroup).subscribe(stockSymbol, callback),
    () => StockDataStore.getInstance(stockGroup).getSnapshot(stockSymbol),
    /* () => ({ value: 'Loading...' }) // Fallback if no data yet */
  );

  return (
    stockRowData && <TableRow key={rowId} id={rowId} tabIndex={-1} ref={cellRef}>
      {columns?.length
        ? columns.map((dataType, index) => (
            <MemoizedScannerCell
              key={stockRowData.getField(dataType.key).getKey()}
              stockDataField={stockRowData.getField(dataType.key)}
              highlightOnChange={dataType.highlightOnChange}
            />
          ))
        : null}
    </TableRow>
  );
};
// export default ScannerRow;
// Until backend return a unique ID per row this is inaccurate
// This provides a significant performance advantage
export default React.memo(ScannerRow, (prev, next) => {
  // Compare the StockDataEntry object update timestamp
  // If previous is same or more recent timestampe skip render
  const entrySame = prev.stockDataEntry.getTimestamp() >= next.stockDataEntry.getTimestamp();

  // Return true to skip re-render, false to allow it
  return entrySame;

});
