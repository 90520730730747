import React from 'react';
import StockDataField from './StockDataField';
import FieldNumberStringArrow from '../Components/Fields/FieldNumberStringArrow';

export default class StockDataFieldNumberStringArrow extends StockDataField {
  static getBalancedSortingValue(columnValue, baseWeight = 100000) {
    let sortingValue = baseWeight;

    // If null or undefined no value
    if (columnValue == null) { 
      return 0;
    }

    // If it is a number at its value to the sorting
    if (typeof columnValue === 'number' && !Number.isNaN(columnValue)) {
      sortingValue += columnValue;
    } else if (typeof columnValue === 'boolean') {
      // If it is a boolean add 1 if true substract 1 if false
      sortingValue += columnValue ? 1 : -1;
    }

    return sortingValue;
  }

  // the joined-column strategy needs to be reviewed until then this complexity seems to be needed
  // This is a port of the original strategy
  getSortingValue(key, defaultValue = null) {
    const columnA = this.getColumn('A'); // Number
    const columnB = this.getColumn('B'); // String
    const columnC = this.getColumn('C'); // Arrow
    
    let sortingValue = 0;

    if (columnA) {
      sortingValue += this.getNumberSortingValue()
    }
    if (columnB) {
      sortingValue += this.getAPlusColorSortingValue();
    }

    if (columnC) {
      sortingValue += this.getArrowSortingValue();
    }

    return sortingValue;
  }

  getNumberValue() {
    return this.getColumnValue('A');
  }

  getNumberSortingValue() {
    // *10 to give it more weight to the value than other columns
    return StockDataFieldNumberStringArrow.getBalancedSortingValue(this.getNumberValue()*10, 100000);
  }

  getAPlusColorValue() {
    // For now, APlus value reside in style color, value is always the same (backend to be changed)
    const style = this.getColumnStyle('B');

    if (!style?.backgroundColor) {
      return '';
    }

    if (style?.backgroundColor === 'rgba(181, 14, 26, 0.5)') {
      return 'red';
    }

    return 'green';
  }

  getAPlusColorSortingValue() {
    const value = this.getAPlusColorValue();
    if (value === '') {
      return 0;
    }
    let sortingValue = 1;
    if (this.getAPlusColorValue() === 'red') {
      sortingValue = 0;
    }
    return StockDataFieldNumberStringArrow.getBalancedSortingValue(sortingValue, 10000);
  }

  getArrowValue() {
    return this.getColumnValue('C');
  }

  getArrowSortingValue() {
    const value = this.getArrowValue();
    return StockDataFieldNumberStringArrow.getBalancedSortingValue(value, 1000);
  }

  getMarkup() {
    return (<FieldNumberStringArrow stockDataField={this} />);
  }
}


