import {createContext} from 'react';

const AppContext = createContext({
  modalShow: false,
  scannerLayout: false,
  modalIsOpen: false,
  showOptionsModal: false,
  showLockedModal: false,
  showUpcomingModal: false,
  watchlists: null,
  activeWatchlist: null,
  activeWatchlistSymbols: [],
  theme: 'light',
  showAIModal: false,
  formatPrice: {},
  activeScanner: {},
  setTheme: () => {},
  closeChart: () => {},
  closeModal: () => {},
  openOptionsModal: () => {},
  closeOptionsModal: () => {},
  openLockedModal: () => {},
  closeLockedModal: () => {},
  openUpcomingModal: () => {},
  closeUpcomingModal: () => {},
  handleAddToWatchlist: () => {},
  getSymbolData: () => {},
  setUpdatingDate: () => {},
  openAIModal: () => {},
  closeAIModal: () => {},
});

export default AppContext;
