import React, {useContext, useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import WatchlistTableHeader from './WatchlistTableHeader';
import {stableSort} from '../../Helpers/tableHelpers';
import ScannerRow from '../Scanner/ScannerRow';
import AppContext from '../../Context/AppContext';

const WatchlistTable = ({
  page,
  dataTypes,
  orderBy,
  order,
  rowsPerPage,
}) => {
  const [orderByState, setOrderBy] = useState(orderBy ?? 'Symbol');
  const [orderState, setOrder] = useState(order ?? 'asc');
  const [orderedData, setOrderedData] = useState([]);
  const [rowsPerPageState, setRowsPerPage] = useState(rowsPerPage ?? 100);
  const [pageState, setPage] = useState(page ?? 1);
  const [filterType, setFilterType] = useState(dataTypes?.find((type) => type.key === orderByState) ?? {});
  const {activeWatchlistSymbols, activeScanner} = useContext(AppContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderByState === property && orderState === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrderBy(property);
    setOrder(newOrder);
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
  };

  // Avoid a layout jump when reaching the last page with empty orderedData.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderedData?.length) : 0;

  useEffect(() => {
    setFilterType(dataTypes?.find((type) => type.key === orderByState) ?? {});
  }, [dataTypes, orderByState]);

  /*
  // Check the allDB external store for the watchlist
  const stockStoreAllGroup = StockDataStore.getInstance(allDB);
  const stockDataEntries = symbolsOfWatchlistArr.map(
    (symbol) =>
      stockStoreAllGroup.getSnapshot(symbol)
  ).filter(Boolean);
  */
  // Keep orderredData up-to-date with with externalStore data from stock group
  // TODO extract only the watchlist
  useEffect(() => {
    const unsubscribe = activeScanner.getStockStore().subscribeToSortedData((sortedStockData, currentSortField) => {
      // This [..sortedStockData] is needed instead of sortedStockData
      // This is due because the reference of objects do not change only the data inside
      // this has some performance cost but negligible for now
      setOrderedData([...sortedStockData]);
    });

    // Perform initial sorting on mount
    // The sorting column and direction is saved, and data is updated in the right order instead of resorted completely at every update 
    activeScanner.getStockStore().sort(orderByState, orderState);

    return () => {
      unsubscribe(); // Clean up on unmount ex. when groupName or orderByState changes
    };
  }, [activeScanner, orderByState, orderState]);

  // TODO This should be reusing the scanner element or table, everything is duplicated for no apparent benefit
  return (
    <Box sx={{width: 'auto', mx: 'auto'}}>
      <Paper sx={{width: 'auto', mb: 2}}>
        <TableContainer sx={{maxHeight: '80vh'}}>
          <Table stickyHeader sx={{minWidth: 0}} aria-labelledby="tableTitle" size="small">
            {dataTypes.length > 0 && (
              <WatchlistTableHeader
                order={orderState}
                orderBy={orderByState}
                onRequestSort={handleRequestSort}
                tableHeaders={dataTypes}
              />
            )}
            {orderedData && orderedData.length ? (
              <TableBody>
                {orderedData.map((stockDataEntry, index) => (
                    <ScannerRow
                      key={`${stockDataEntry.getEntryId()}-row`}
                      stockDataEntry={stockDataEntry}
                      columns={dataTypes}
                    />
                  ))
                  .slice(pageState * rowsPerPageState, pageState * rowsPerPageState + rowsPerPageState)}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <h1 className="no-results">
                      {!orderedData?.length ? 'No Items Saved To Watchlist' : 'No Items Saved To Watchlist'}
                    </h1>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={orderedData?.length ?? 0}
          rowsPerPage={rowsPerPageState}
          page={pageState}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Paper>
    </Box>
  );
};

// export default React.memo(WatchlistTable, (prev, next) => {
//   const dataSame = isEqual(prev?.tableData, next?.tableData);
//   const orderBySame = prev?.orderBy === next?.orderBy;
//   const orderSame = prev?.order === next?.order;
//   const pageSame = prev?.page === next?.page;
//   const rowsPerPageSame = prev?.rowsPerPage === next?.rowsPerPage;
//   return dataSame && orderBySame && orderSame && pageSame && rowsPerPageSame && watchlistSame;
// });
export default WatchlistTable;
