import React, {useContext} from 'react'; 
import {ReactComponent as SamSpeaksIcon} from '../../Icons/SAM-speaks.svg';
import AppContext from '../../Context/AppContext';

const FieldSamSpeaks = ({
  symbol,
  style
}) => {
  // openAIModal is defined in Scanner.js and used only here
  const {openAIModal, activeScanner} = useContext(AppContext);

  if (!activeScanner.getIsUseAi() || !symbol) {
    return null;
  }

  return (
    <button
      onClick={() => {
        openAIModal(symbol);
      }}
      type="button"
    >
      <SamSpeaksIcon  alt="SAM Speaks" style={style} className="ai-item" />
    </button>
  );
}

export default FieldSamSpeaks
