import React,{useContext} from 'react'; 
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import AppContext from '../../Context/AppContext';

const FieldPopOut = ({
  stockDataFieldPopOut,
  fieldName,
  stockSymbol,
  groupName
}) => {
  const {openOptionsModal, activeScanner} = useContext(AppContext);

  if (!stockDataFieldPopOut.hasOptionsPopOut()) {
    return null
  }
  const openOptions = () => {
    if (!openOptionsModal) return;
    openOptionsModal(stockSymbol, groupName, fieldName);
  };

  const fieldOption = activeScanner.getOptionsColumns()[fieldName];

  return (
        <button
          type="button"
          label="open options"
          onClick={() => {
            openOptions();
          }}
          className={`open-options ${fieldOption?.overrideOpenIcon ? 'override-open-icon' : ''}`}
        >
          {fieldOption?.overrideOpenIcon && fieldOption?.overrideOpenIcon?.icon ? (
            <img
              src={fieldOption?.overrideOpenIcon?.icon}
              alt="open-options"
              style={fieldOption?.overrideOpenIcon?.style ?? {}}
              className="open-options-icon"
            />
          ) : (
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            )}
        </button>
  );
}

export default FieldPopOut
