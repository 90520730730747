/* eslint-disable camelcase */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React, {useContext, useState, useEffect} from 'react';
import TableCell from '@mui/material/TableCell';
import {isEqual} from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import AppContext from '../../Context/AppContext';

const ScannerCell = ({
  stockDataField,
  highlightOnChange
}) => {
  const {openLockedModal, activeScanner} = useContext(AppContext);

  const [currentValue, setCurrentValue] = useState(stockDataField.getValue());
  const [changeClass, setChangeClass] = useState(stockDataField.getName());

  // Apply increase or decrease animation class 
  // on eligible columns determined by highlighKeys context
  if (highlightOnChange === true) {
    useEffect(() => {
      const newValue = stockDataField.getValue();
      const baseClass = stockDataField.getName();
      let hasChanged = true;
      if (newValue > currentValue) {
        setChangeClass(`${baseClass} increase-value`);
      } else if (newValue < currentValue) {
        setChangeClass(`${baseClass} decrease-value`);
      } else {
        hasChanged = false;
        setChangeClass(baseClass); // No change
      }

      // Update the current value after class application
      setCurrentValue(newValue);

      if (!hasChanged) {
        return () => {};
      }

      // If changed applied, remove the changed after 1 second
      const timeout = setTimeout(() => setChangeClass(`${baseClass} animation-out`), 500);

      return () => clearTimeout(timeout); // Cleanup timeout on component unmount
    }, [stockDataField]);
  }

  return (
    <TableCell
      key={stockDataField.getKey()}
      className={changeClass}
      align="center"
    >
      <span className="scanner-item">
        {!activeScanner.isColumnLocked(stockDataField.getName()) ? (
          stockDataField.getMarkup()
        ) : (
          // If field is locked, display button to open modal
          <button
            type="button"
            label="open options"
            onClick={() => {
              if (!openLockedModal) return;
              openLockedModal();
            }}
            className="open-options"
          >
            <FontAwesomeIcon icon={faLock} />
          </button>
        )}
      </span>
    </TableCell>
  );
}

const MemoizedScannerCell = React.memo(ScannerCell, (prev, next) => {
  // TODO use better comparison strategy - like create a low cpu "hash" or string ID from backend
  const entrySame = isEqual(prev.stockDataField.getData() === next.stockDataField.getData());

  return entrySame;
});

export default MemoizedScannerCell;
