import StockDataFieldFactory from './StockDataFieldFactory'

class StockDataEntry {
  constructor(group, data) {
    this.group = group;
    this.data = data;
    this.timestamp = null;
  }

  getGroup() {
    return this.group || '';
  }

  getData() {
    return this.data || null;
  }

  setTimestamp(timestamp) {
    this.timestamp = timestamp;
  }

  // This is used for data change comparison
  // Note _id seems always the same for each stock no matter the update
  getTimestamp() {
    return this.timestamp;
  }

  getSymbol() {
    return this.getValue('Symbol');
  }

  getEntryId() {
    return `${this.group}-${this.getValue('Symbol')}`;
  }

  isPropExist(key) {
    return key in this.data;
  }

  // This is used the value that will be used for sorting purposes
  getSortingValue(key, defaultValue = null) {
    return this.getValue(key, defaultValue)
  }

  // Method to get a value by key, with optional default
  getValue(key, defaultValue = null) {
    return this.data[key]?.value ?? defaultValue;
  }

  // Get style properties for a given key
  getStyle(key) {
    return this.data[key]?.style || {};
  }

  getField(key) {
    return StockDataFieldFactory.create(key, this.data[key], this);
  }

}

export default StockDataEntry;
