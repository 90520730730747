import {orderBy} from 'lodash';
import StockDataEntry from './Models/StockDataEntry'

class StockDataStore {
  static registry = new Map(); // Registry for all store instances by group

  constructor(groupName) {
    // Store group
    this.group = groupName;

    // This is an object indexed by stock [Symbol]
    // Because of non-numerical indexes it will behave as an object so explicitely use object
    this.storeData = {}; // Store current data for all rows

    // Store Data sorted
    this.storeDataSorted = []; // Store sorted data
    this.sortByField = 'Symbol'; 
    this.sortDirection = 'asc'; // Default sort direction

    // Subscribers
    this.entrySubscribers = new Map(); // Subscribers for individual rows
    this.newRowSubscribers = new Set(); // Subscribers for new rows
    this.changeSubscribers = new Set(); // Subscribers for store changes
    this.sortedDataSubscribers = new Set(); // Subscribers for sorted data update
  }

  // Static methods for the registry
  /**
   * Retrieve or create a store for a specific group.
   * @param {string} groupName
   * @returns {StockDataStore}
   */
  static getInstance(groupName) {
    if (!this.registry.has(groupName)) {
      const newStore = new StockDataStore(groupName);
      this.registry.set(groupName, newStore);
    }
    return this.registry.get(groupName);
  }

  static exists(groupName) {
    return this.registry.has(groupName);
  }

  /**
   * Delete a store for a specific group.
   * @param {string} groupName
   */
  static deleteStore(groupName) {
    this.registry.delete(groupName);
  }

  /**
   * Clear all stores from the registry.
   */
  static clearRegistry() {
    this.registry.clear();
  }

  // Notifiers -------------
  // Function to notify subscribers of a specific row update
  notifyRowSubscribers(rowId) {
    const rowSubscribers = this.entrySubscribers.get(rowId);
    if (rowSubscribers) {
      rowSubscribers.forEach((callback) => callback());
    }
  };

  // Notify global subscribers for new rows
  notifyNewRowSubscribers(newRow) {
    this.newRowSubscribers.forEach((callback) => callback(newRow));
  };

  // Notify global change subscribers
  notifyChangeSubscribers() {
    this.changeSubscribers.forEach((callback) => callback());
  };

  // Notify subscribers of sorted data for a specific group
  notifySortedDataSubscribers() {
    this.sortedDataSubscribers.forEach((callback) => {
      callback(this.storeDataSorted, this.sortByFields);
    });
  };

  // Subscribers -----------
  // Subscribe to updates for a specific symbol in this store
  subscribe(symbol, callback) {
    if (!this.entrySubscribers.has(symbol)) {
      this.entrySubscribers.set(symbol, []);
    }
    this.entrySubscribers.get(symbol).push(callback);

    // Unsubscribe
    return () => {
      const updatedSubscribers = this.entrySubscribers.get(symbol).filter((cb) => cb !== callback);
      if (updatedSubscribers.length === 0) {
        this.entrySubscribers.delete(symbol); // No more subscribers for this row
      } else {
        this.entrySubscribers.set(symbol, updatedSubscribers);
      }
    };
  };

  // Subscribe globally to additions of new rows
  subscribeToNewRows(callback) {
    this.newRowSubscribers.add(callback);

    // Unsubscribe
    return () => {
      this.newRowSubscribers.delete(callback);
    };
  };

  // Subscribe to storeData changes
  subscribeToChanges(callback) {
    this.changeSubscribers.add(callback);

    // Unsubscribe
    return () => {
      this.changeSubscribers.delete(callback);
    };
  };

  // Subscribe to sorted data changes for a specific group
  subscribeToSortedData(callback) {
    this.sortedDataSubscribers.add(callback);

    // Return unsubscribe function
    return () => {
      this.sortedDataSubscribers.delete(callback);
    };
  };

  // Getters --------------
  // Get the current snapshot of a specific row
  getSnapshot(symbol) {
    return this.storeData[symbol] || null;
  }

  // Get current sortByField for the store
  getSortByField() {
    return this.sortByField || "Symbol";
  }

  getSortDirection() {
    return this.sortDirection || "asc";
  }

  // Sorting Actions ----------
  // Sort and update storeDataSorted
  sort(sortByField, sortDirection = "asc") {
    this.sortByField = sortByField;
    this.sortDirection = sortDirection;

    // Sort the data and save it
    this.storeDataSorted = orderBy(
      Object.values(this.storeData),
      (stockDataEntry) => stockDataEntry.getField(sortByField).getSortingValue(),
      [sortDirection]
    );

    this.notifySortedDataSubscribers();
  }

  // Update Actions ---------
  // Update the storeData (used in `stateview:join`)
  update(newData) {
    const timestamp = Date.now();
    const currentSortByField = this.getSortByField();
    const currentSortDirection = this.getSortDirection();

    // comparator
    const comparator = (a, b) => {
      const valueA = a.getSortingValue(currentSortByField);
      const valueB = b.getSortingValue(currentSortByField);

      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    };

    // TODO ??
    // To push this further, we could potentially perform all the data sorting update
    // in one traversal for all the newData entries instead of each separately and retraverse 
    // the whole array again
    newData.forEach((stockUpdateObj) => {
      const stockEntry = new StockDataEntry(this.group, stockUpdateObj);
      // Add update timestamp to be able to detect more recent updates
      stockEntry.setTimestamp(timestamp);
      const symbol = stockEntry.getSymbol();
      const rowId = stockEntry.getEntryId();

      // Check if this is a new row
      const isNewRow = !this.storeData[symbol];

      // Add or update the storeData by symbol
      this.storeData[symbol] = stockEntry;

      // Add at the right place in the previously sorted array
      let updated = false; // Whether the entry has been updated/added
      let removed = false; // Whether the old entry has been removed

      // Traverse the sorted array only once to
      // 1. remove the previous entry, if it existed (isNewRow is false)
      // 2. Insert the new entry at the right place to keep sorted
      for (let i = 0; i < this.storeDataSorted.length; i += 1) {
        const currentEntry = this.storeDataSorted[i];

        // Check if this is the previous entry we need to remove
        if (!isNewRow && !removed && currentEntry.getSymbol() === symbol) {
          // Remove the old entry
          this.storeDataSorted.splice(i, 1);
          removed = true;
          i-=1; // Adjust index due to removal
          /* eslint-disable-next-line no-continue */
          continue; // Reevaluate the current index
        }

        // Insert the new entry if it needs to come before the current entry
        if (!updated && 
          (
            ((currentSortDirection === "desc") && comparator(stockEntry, currentEntry) > 0) || 
            ((currentSortDirection !== "desc") && comparator(stockEntry, currentEntry) < 0)
          )
        ) {
          this.storeDataSorted.splice(i, 0, stockEntry); // Insert new entry
          updated = true;
        }

        // Break early if what had to be done has been done
        if (updated && (removed || isNewRow)) {
          break;
        }
      }

      // If the new entry wasn't inserted, add it to the end
      if (!updated) {
        this.storeDataSorted.push(stockEntry);
      }


      // Row specific updates subscribers
      // notifyRowSubscribers(rowId);

      // Notify New Row subscribers 
      if (isNewRow) {
        this.notifyNewRowSubscribers(stockEntry);
      }

    });

    // Notify change subscribers - (sorting, filtering)
    // notifyChangeSubscribers();

    this.notifySortedDataSubscribers(this.group);
  };
}

export default StockDataStore;
