import {createContext} from 'react';

const ScannerContext = createContext({
    group: {},
    // Columns that could get highlighted on value increase/decrease
    highlightColumns: new Set(),
    displayColumns: [],
    groupNumber: null,
    multiScreen: false,
    hasChild: false,
    optionsColumns: [],
    optionsAllowed: false,
    lockedColumns: [],
    groupName: '',
    displayChartDisabledMessage: false,
    handleUpdateMultiSelect: () => {},
    hasFilters: () => {},
    handleChangePage: () => {},
});

// group,
// groupNumber,
// multiScreen,
// hasChild,
// optionsColumns,
// optionsAllowed,
// lockedColumns,
// groupName,
// displayChartDisabledMessage,
export default ScannerContext;
