import React from 'react';
import StockDataField from './StockDataField';
import FieldPopOut from '../Components/Fields/FieldPopOut';

export default class StockDataFieldPopOut extends StockDataField {
  static popOutColumnsKeys = [];

  // This should be set at app context init
  static setPopOutColumns(configKeys) {
    if (!configKeys?.DPO_Popout?.columns?.length) {
      return;
    }
    this.popOutColumnsKeys = configKeys.DPO_Popout.columns;
  }

  hasOptionsPopOut() {
    // if any popout columns keys is found in the entry
    return StockDataFieldPopOut.popOutColumnsKeys?.length
      ? Object.keys(this.parentEntry.getData() ?? {}).some((r) => StockDataFieldPopOut.popOutColumnsKeys?.includes(r))
      : false;
  }

  getSortingValue(defaultValue = null) {
    return this.hasOptionsPopOut();
  }

  getMarkup() {
    return (<FieldPopOut stockDataFieldPopOut={this} stockSymbol={this.getEntry().getSymbol()} fieldName={this.getName()} groupName={this.getGroup()}  />);
  }
}


