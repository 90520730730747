import StockDataStore from '../externalStoreStocksData';

class Scanner {

  static registry = new Map(); // Registry for all scanner instances by group

  static getInstance(name) {
    if (!this.registry.has(name)) {
      const newScanner = new Scanner(name);
      this.registry.set(name, newScanner);
    }
    return this.registry.get(name);
  }

  constructor(name) {
    /*
    this.group = {};
    // Columns that could get highlighted on value increase/decrease
    this.highlightColumns = new Set();
    this.displayColumns = [];
    this.groupNumber = null;
    this.multiScreen = false;
    this.hasChild = false;
    this.optionsColumns = [];
    this.optionsAllowed = false;
    this.lockedColumns = [];
    this.groupName = '';
    this.displayChartDisabledMessage=  false;
    this.handleUpdateMultiSelect = () => {};
    this.hasFilters = () => {};
    this.handleChangePage = () => {};
    */

    // For now add properties needed and grow from there
    this.name = name || ''; // Formerly group name should match StockDataStore name
    this.type = 'stateview';

    // Tab order
    // this.tabIndex = null;

    this.isUserAllowedChart = false;
    this.isUserAllowedWatchlist = false;

    this.optionsColumns = {};
    this.isOptionsAllowed = false;
    this.lockedColumns = {};
    this.isUseAi = false;
  }

  getStockStore() {
    return StockDataStore.getInstance(this.name);
  }

  getIsUserAllowedChart() {
    return this.isUserAllowedChart;
  }

  setIsUserAllowedChart(allowedChart) {
    this.isUserAllowedChart = allowedChart;

    return this;
  }

  getIsUserAllowedWatchlist() {
    return this.isUserAllowedWatchlist;
  }

  setIsUserAllowedWatchlist(allowedWatchlist) {
    this.isUserAllowedWatchlist = allowedWatchlist;

    return this;
  }

  getOptionsColumns() {
    return this.optionsColumns;
  }

  isWatchlist() {
    return this.type === 'activeWatchlist' || false;
  }

  // Method to check if field is locked for this scanner
  isColumnLocked(column) {
    const isLocked = this.lockedColumns?.length && this.lockedColumns.includes(column);
    if (isLocked === true) {
      return true;
    }

    // Funky...
    switch (column) {
      // Popout
      case 'DPO_Popout':
        // If optionsAllowed is not allowed it is locked: return true
        return !this.isOptionsAllowed;

      default:
    }
    return false;
  }

  getIsUseAi() {
    return this.isUseAi;
  }
  /*
  // Scanner methods imported from old that likely should be refatored
  static openModal = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  static getSymbolData = useCallback((symbol, gr) => {
    const grName = typeof gr === 'string' ? gr : gr?.group;
    // dispatch({type: 'SET_SYMBOL_TO_CHART', payload: {symbol, group: grName}});
    setSymbolToChart(symbol);
    setGroupToChart(grName);
    setTimeout(() => {
      openModal();
    }, 1000);
  }, []);
  */
}

export default Scanner;
