import Scanner from './Scanner';

class ScannerFactory {

  // Initial method to get create from old code state / scannerData structure
  // Ideally the old structure is dropped and this is udpated and/or replaced with proper one
  // This will likely co-exist with old structure for now
  static createFromOld(groupInfo) {

    const scanner = Scanner.getInstance(groupInfo.group);

    scanner.type = groupInfo.type || null;
    scanner.isUserAllowedWatchlist = groupInfo.allowedWatchlist || false;
    scanner.isUserAllowedChart = groupInfo.allowedChart || false;


    return scanner;
  }
}
export default ScannerFactory;

